import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import banner from "../../assets/banner_img2.jpg";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { AiOutlineConsoleSql } from "react-icons/ai";
import {
  MdQueryStats,
  MdOutlineHighQuality,
  MdMarkEmailRead,
} from "react-icons/md";
import cs from "../../assets/content-media.gif";
import abm from "../../assets/abm.gif";
import bant from "../../assets/bant.png";
import email from "../../assets/mail.gif";
import { Link as ScrollLink } from "react-scroll";

const Banner = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Box position="relative">
      {/* Banner Section */}
      <Swiper
        spaceBetween={30}
        pagination={{ clickable: true }}
        style={{ width: "100%", height: isMobile ? "50vh" : "70vh" }}
      >
        <SwiperSlide>
          <Box
            backgroundImage={`url(${banner})`}
            backgroundSize="cover"
            backgroundPosition="center"
            height="100%"
            position="relative"
          >
            <Flex
              justify="flex-start"
              height="100%"
              bg="rgba(0, 0, 0, 0.4)"
              color="white"
              p={isMobile ? 4 : 8}
              pt={isMobile ? 4 : 60}
              pl={isMobile ? 4 : 60}
            >
              <VStack spacing={4} align="flex-start">
                <Text
                  fontSize={isMobile ? "2xl" : "6xl"}
                  fontWeight="bold"
                  filter="drop-shadow(1px 1px 8px #E9BD36)"
                >
                  WELCOME TO EXPLORE B2B LEADS
                </Text>
                <Text
                  maxW="3xl"
                  fontSize={isMobile ? "md" : "4xl"}
                  textAlign="left"
                >
                  We find the right audience for your future sales.
                </Text>
                {/* <Button bg="#E9BD36" size="lg" _hover={{ bg: "#f0c40d" }}>
                  Get Started
                </Button> */}
                <ScrollLink to="contact-us" smooth={true} spy={true}>
                  <Link fontSize={["sm", "md", "lg"]} fontWeight={"bold"}>
                    <Button bg="#E9BD36" size="lg" _hover={{ bg: "#f0c40d" }}>
                      Get Started
                    </Button>
                  </Link>
                </ScrollLink>
              </VStack>
            </Flex>
          </Box>
        </SwiperSlide>
      </Swiper>

      {/* Black Box Section */}
      <Box
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        width={{ base: "95%", md: "85%", lg: "75%" }}
        bg="black"
        color="white"
        zIndex={5}
        boxShadow="2xl"
        borderRadius={20}
        top="85%"
        py={8}
        px={8}
        //role="group"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          align="center"
        >
          <Text
            fontSize={{ base: "sm", md: "lg", lg: "xl" }}
            fontWeight="bold"
            textAlign={{ base: "center", md: "left" }}
            w={{ base: "100%", md: "40%" }}
            mb={{ base: 4, md: 0 }}
          >
            YOUR TRUSTED PLATFORM <br />
            FOR QUALIFIED LEADS
          </Text>

          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 4 },
            }}
            pagination={{ clickable: true }}
            //navigation={{ clickable: true }}
            style={{ width: "60%" }}
          >
            {[
              {
                icon: <AiOutlineConsoleSql />,
                image: cs,
                heading: "Content Syndication",
                text: "We offer CPL content syndication, Whitepapers campaigns including MQLs, SQLs, and HQLs.",
              },
              {
                icon: <MdQueryStats />,
                image: abm,
                heading: "ABM",
                text: "We specialize in ABM, providing tailored lead generation strategies that engage high-value accounts.",
              },
              {
                icon: <MdOutlineHighQuality />,
                image: bant,
                heading: "BANT",
                text: "BANT, ANUM, and CHAMP frameworks qualify leads precisely, directing your team to top opportunities.",
              },
              {
                icon: <MdMarkEmailRead />,
                image: email,
                heading: "Email Marketing",
                text: "We create tailored email campaigns using metrics and opt-in lists for high-impact engagement and CTAs.",
              },
            ].map((slide, index) => (
              <SwiperSlide key={index}>
                <Box
                  //bg="#f0c40d"
                  bg="#E9BD36"
                  borderRadius="3xl"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  _hover={{
                    //transform: "scale(1.15)",
                    transform: "rotateY(360deg)",
                    boxShadow: "xl",
                    bg: "white",
                    color: "black",
                    borderRadius: "3xl",
                    //transition: "transform 0.2s",
                    transition: "transform 0.2s",
                    transformStyle: "preserve-3d",
                  }}
                  style={{
                    minHeight: hoveredIndex === index ? "15vh" : "15vh",
                    //transition: "transform 0.3s ease-out",
                    //overflow: "hidden",
                    perspective: "1000px",
                    //transformStyle: "preserve-3d",
                    transition: "transform 0.6s ease, box-shadow 0.4s ease",
                  }}
                >
                  {/* <Box
                    mb={2}
                    style={{
                      backfaceVisibility: "hidden",
                    }}
                  >
                    <Image
                      src={slide.image}
                      alt="custom"
                      boxSize="50px"
                      objectFit="cover"
                      mixBlendMode="multiply"
                      transition="transform 0.3s ease-in-out"
                    />
                  </Box>
                  <Heading fontSize={{ base: "sm", md: "md", lg: "xl" }}>
                    {slide.heading}
                  </Heading>
                  {hoveredIndex === index && (
                    <Text mt={2} fontSize={{ base: "xs", md: "sm", lg: "sm" }}>
                      {slide.text}
                    </Text>
                  )} */}
                  {hoveredIndex === index ? (
                    <>
                      <Image
                        src={slide.image}
                        alt="custom"
                        boxSize="40px"
                        objectFit="cover"
                        mixBlendMode="multiply"
                        transition="transform 0.3s ease-in-out"
                      />
                      <Text
                        mt={2}
                        fontSize={{ base: "xs", md: "sm", lg: "sm" }}
                      >
                        {slide.text}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Box
                        mb={2}
                        style={{
                          backfaceVisibility: "hidden",
                        }}
                      >
                        <Image
                          src={slide.image}
                          alt="custom"
                          boxSize="50px"
                          objectFit="cover"
                          mixBlendMode="multiply"
                          transition="transform 0.3s ease-in-out"
                        />
                      </Box>
                      <Heading fontSize={{ base: "sm", md: "md", lg: "xl" }}>
                        {slide.heading}
                      </Heading>
                    </>
                  )}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      </Box>
    </Box>
  );
};

export default Banner;
