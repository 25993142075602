import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  Text,
  Icon,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { PhoneIcon, HamburgerIcon,EmailIcon } from "@chakra-ui/icons";
import { Link as ScrollLink } from "react-scroll";
import logo from "../assets/eanddyellowtag.png";

const Navbar = () => {
  const [isSticky, setSticky] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  return (
    <Box position="relative" zIndex={999}>
      {/* Top White Strip */}
      <Flex
        bg="#FAF9F6"
        justifyContent="space-between"
        alignItems="center"
        p={4}
        position="relative"
        //zIndex={10}
      >
        {/* Logo */}
        <HStack spacing={3}>
          <Box as="img" src={logo} alt="logo" w={200} />
        </HStack>

        {/* Contact Info */}
        <VStack spacing={1} display={["none", "none", "flex"]}>
          <HStack fontWeight={"bold"}   alignSelf={"flex-start"}>
            <Icon as={EmailIcon} color={"#f0c40d"} />
            <Text fontSize={["sm", "md", "lg"]} 
            >
              info@exploreb2blead.com
            </Text>
          </HStack>
          <HStack fontWeight={"bold"}   alignSelf={"flex-start"}>
            <Icon as={PhoneIcon} color={"#f0c40d"} />
            <Text fontSize={["sm", "md", "lg"]} 
            >
               +1 980 224 8664
            </Text>
          </HStack>
        </VStack>

        {/* Hamburger Icon for mobile */}
        <IconButton
          aria-label="Open Menu"
          icon={<HamburgerIcon />}
          display={["flex", "flex", "none"]}
          onClick={onOpen}
        />
      </Flex>

      {/* Sticky Navbar */}
      <Box
        as="nav"
        position={isSticky ? "fixed" : "absolute"}
        top={isSticky ? 0 : "45px"}
        left="50%"
        transform="translateX(-50%)"
        width="65%"
        bg="black"
        color="white"
        zIndex={5}
        boxShadow={isSticky ? "md" : "none"}
        transition="top 0.3s ease-in-out"
        borderRadius={10}
      >
        <Flex justifyContent="center" alignItems="center" height="100%" py={4}>
          <HStack spacing={20} display={["none", "none", "flex"]}>
            <ScrollLink
              to="about-us"
              smooth={true}
              spy={true}
              onSetActive={handleSetActive}
            >
              <Link
                fontSize={["sm", "md", "lg"]}
                fontWeight={"bold"}
                color={activeSection === "about-us" ? "#f0c40d" : "white"}
              >
                About Us
              </Link>
            </ScrollLink>
            <ScrollLink
              to="services"
              smooth={true}
              spy={true}
              onSetActive={handleSetActive}
            >
              <Link
                fontSize={["sm", "md", "lg"]}
                fontWeight={"bold"}
                color={activeSection === "services" ? "#f0c40d" : "white"}
              >
                Services
              </Link>
            </ScrollLink>
            <ScrollLink
              to="our-process"
              smooth={true}
              spy={true}
              onSetActive={handleSetActive}
            >
              <Link
                fontSize={["sm", "md", "lg"]}
                fontWeight={"bold"}
                color={activeSection === "our-process" ? "#f0c40d" : "white"}
              >
                Our Process
              </Link>
            </ScrollLink>
            <ScrollLink
              to="gdpr"
              smooth={true}
              spy={true}
              onSetActive={handleSetActive}
            >
              <Link
                fontSize={["sm", "md", "lg"]}
                fontWeight={"bold"}
                color={activeSection === "gdpr" ? "#f0c40d" : "white"}
              >
                GDPR
              </Link>
            </ScrollLink>
            <ScrollLink
              to="contact-us"
              smooth={true}
              spy={true}
              onSetActive={handleSetActive}
            >
              <Link
                fontSize={["sm", "md", "lg"]}
                fontWeight={"bold"}
                color={activeSection === "contact-us" ? "#f0c40d" : "white"}
              >
                Contact Us
              </Link>
            </ScrollLink>
          </HStack>
        </Flex>

        {/* Yellow underline */}
        <Box bg="#f0c40d" height="5px" width="100%" borderRadius={10} />
      </Box>

      {/* Mobile Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={6} align="start">
              <ScrollLink
                to="about-us"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={activeSection === "about-us" ? "#f0c40d" : "black"}
                >
                  About Us
                </Link>
              </ScrollLink>
              <ScrollLink
                to="services"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={activeSection === "services" ? "#f0c40d" : "black"}
                >
                  Services
                </Link>
              </ScrollLink>
              <ScrollLink
                to="our-process"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={
                    activeSection === "our-process" ? "#f0c40d" : "black"
                  }
                >
                  Our Process
                </Link>
              </ScrollLink>
              <ScrollLink
                to="gdpr"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={activeSection === "gdpr" ? "#f0c40d" : "black"}
                >
                  GDPR
                </Link>
              </ScrollLink>
              <ScrollLink
                to="contact-us"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={
                    activeSection === "contact-us" ? "#f0c40d" : "black"
                  }
                >
                  Contact Us
                </Link>
              </ScrollLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;
