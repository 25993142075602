import React from "react";
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Link,
  Input,
  Button,
  Icon,
  Divider,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  EmailIcon,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaHome,
  FaMailBulk,
  FaPhoneAlt,
  FaFirefox,
} from "react-icons/fa";
import logo from "../assets/eanddyellowtag.png"; // Replace with your logo path
import { Link as ScrollLink } from "react-scroll";
const Footer = () => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });

  return (
    <Box bg="gray.50" py={10} px={5}>
      <Flex
        justifyContent="space-evenly"
        flexDirection={flexDirection}
        alignItems={flexDirection === "row" ? "flex-start" : "center"}
      >
        {/* Logo and Description */}
        <VStack align={["center", "flex-start"]} mb={5}>
          {/* <Box as="img" src={logo} alt="Logo" mb={4} w={300} /> */}
          <Box
            as="img"
            src={logo}
            alt="Logo"
            mb={4}
            w={300}
            display="block"
            marginLeft={0}
          />

          <Text fontSize="sm" textAlign={["center", "left"]}>
            Partner with us to turn your demand generation <br /> challenges
            into growth opportunities. <br /> Let’s explore the possibilities
            together!
          </Text>
          {/* Social Icons */}
          <HStack spacing={3} mt={4}>
            <Link
              href="https://www.linkedin.com/company/explore-and-do-technologies-pvt-ltd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={FaLinkedin} boxSize={6} />
            </Link>
            <Link
              href="https://www.facebook.com/exploreanddo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={FaFacebook} boxSize={6} />
            </Link>
            <Link
              href="https://x.com/i/flow/login?redirect_after_login=%2FAndLtd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={FaTwitter} boxSize={6} />
            </Link>
            <Link
              href="https://www.instagram.com/exploreanddo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={FaInstagram} boxSize={6} />
            </Link>
          </HStack>
        </VStack>

        {/* Footer Links */}
        <HStack
          spacing={8}
          mb={5}
          align="flex-start"
          flexDirection={flexDirection === "row" ? "row" : "column"}
        >
          {/* Company Section */}
          <VStack align="flex-start">
            <Text
              fontWeight="bold"
              filter="drop-shadow(1px 1px 8px #f0c40d)"
              textDecoration={"underline"}
            >
              Services
            </Text>
            <ScrollLink to="services" smooth={true} spy={true} offset={200}>
              <Link>Our Database</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={200}>
              <Link>Lead Generation</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={200}>
              <Link>Content Syndication</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={550}>
              <Link>Account Based Marketing</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={550}>
              <Link>Lead Qualification</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={550}>
              <Link>Email Marketing</Link>
            </ScrollLink>
          </VStack>
        </HStack>

        <VStack align="flex-start" spacing={4} mb={5}>
          <VStack align="flex-start" spacing={1}>
            <Text
              fontWeight="bold"
              filter="drop-shadow(1px 1px 8px #f0c40d)"
              textDecoration={"underline"}
            >
              India Office
            </Text>
            <HStack>
              <Icon as={FaHome} />{" "}
              <Text fontWeight="semibold">4-3-116/1, 1st & 2nd Floor,</Text>
            </HStack>
            <Text fontWeight="semibold">Wase Arcade,</Text>
            <Text fontWeight="semibold">Pillar No-123, Attapur,</Text>
            <Text fontWeight="semibold">Hyderabad, Telangana,</Text>
            <Text fontWeight="semibold">500048-India</Text>
            <VStack align="flex-start" spacing={1} mt={4}>
              <HStack>
                <Icon as={FaFirefox} />{" "}
                <Text fontWeight="semibold">info@exploreb2blead.com</Text>
              </HStack>
            </VStack>
            <VStack align="flex-start" spacing={1} mt={4}>
              <HStack>
                <Icon as={FaPhoneAlt} />{" "}
                <Text fontWeight="semibold">+91 799-581-6886</Text>
              </HStack>
              <HStack>
                <Icon as={FaPhoneAlt} />{" "}
                <Text fontWeight="semibold">+91 810-601-6337</Text>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
        <VStack align="flex-start" spacing={4} mb={5}>
          <VStack align="flex-start" spacing={1}>
            <Text
              fontWeight="bold"
              filter="drop-shadow(1px 1px 8px #f0c40d)"
              textDecoration={"underline"}
            >
              US Office
            </Text>
            <HStack>
              <Icon as={FaHome} />{" "}
              <Text fontWeight="semibold">13000 S Tryon St,</Text>
            </HStack>
            <Text fontWeight="semibold">Charlotte,</Text>
            <Text fontWeight="semibold">NC 28278</Text>
        
            <VStack align="flex-start" spacing={1} mt={4}>
             
            </VStack>
            <VStack align="flex-start" spacing={1} mt={4}>
              <HStack>
                <Icon as={FaPhoneAlt} />{" "}
                <Text fontWeight="semibold">+1 980 224 8664</Text>
              </HStack>
              
            </VStack>
          </VStack>
        </VStack>
      </Flex>

      {/* Footer Divider */}
      <Divider my={6} />

      {/* Copyright Section */}
      <Text fontSize="sm" textAlign="center" color="gray.500">
        © Copyrights exploreanddo.com 2022. All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
