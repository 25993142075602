import { Box, Text, Heading, Icon, Flex } from "@chakra-ui/react";
import { FaLaptop, FaBullhorn, FaLightbulb } from "react-icons/fa";

const Gdpr = () => {
  const cards = [
    {
      icon: FaLaptop,
      title: "Data Protection",
      text: "Data protection involves implementing measures to prevent unauthorized access to computers, websites, and databases. It also safeguards against data loss or corruption, ensuring your information remains secure.",
    },
    {
      icon: FaBullhorn,
      title: "Privacy",
      text: "Data privacy, or information privacy, refers to an organization’s ability to control what data within its computer systems can be shared with third parties. It ensures that sensitive information is protected and used responsibly.",
    },
    {
      icon: FaLightbulb,
      title: "Information Security",
      text: "Information security is built on three core principles: confidentiality, integrity, and availability. We implement a range of solutions designed to protect vital company information from alteration, disruption, destruction, and unauthorized access.",
    },
    {
      icon: FaLightbulb,
      title: "Transparency",
      text: "We handle data with integrity and in compliance with the law. We are committed to informing individuals and businesses about the data we collect, who has access to it, how it’s used, and how they can engage with it. Your trust is our priority.",
    },
  ];

  return (
    <Box
      minH="100vh"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
      position="relative"
      p={5}
    >
      {/* Yellow background shapes */}
      <Box
        position="absolute"
        left={{ base: "5%", md: "10%" }}
        bottom="10%"
        w={{ base: "120px", md: "150px" }}
        h={{ base: "120px", md: "150px" }}
        //bg="#f0c40d"
        bg="#E9BD36"
        borderRadius="full"
        zIndex={1}
      ></Box>
      <Box
        position="absolute"
        left={{ base: "10%", md: "15%" }}
        top="5%"
        w={{ base: "120px", md: "150px" }}
        h={{ base: "120px", md: "150px" }}
        //bg="#f0c40d"
        bg="#E9BD36"
        borderRadius="full"
        zIndex={1}
      ></Box>
      <Box
        position="absolute"
        left={{ base: "20%", md: "30%" }}
        top="10%"
        w={{ base: "80px", md: "100px" }}
        h={{ base: "80px", md: "100px" }}
        //bg="#f0c40d"
        bg="#E9BD36"
        borderRadius="full"
        zIndex={1}
      ></Box>

      {/* Main content container */}
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        align="center"
        zIndex={2}
        maxW="1200px"
        width="100%"
      >
        <Box flex="1">
          <Flex
            direction="column"
            gap={6}
            alignItems="center" // Center align cards
            position="relative"
          >
            <Flex
              direction={{ base: "column", md: "row" }} // Column for mobile, row for medium and above
              justifyContent="space-between"
              alignItems="center"
              p={5}
            >
              <Box
                bg="white"
                p={15}
                boxShadow="lg"
                borderRadius="md"
                flex="1"
                m={2} // Add margin for spacing
                position="relative" // Relative positioning
                minW={{ base: "80%", md: "50%" }} // Set min width for responsiveness
                borderColor="#f0c40d" // Set border color to yellow
                borderWidth="1px" // Set the border width
                transition="transform 0.3s ease-in-out" // Smooth transition
                _hover={{
                  transform: "scale(1.05)", // Slightly increase size on hover
                  boxShadow: "xl", // Increase shadow on hover for effect
                }}
              >
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  mb={{ base: 0, md: 5 }}
                  textAlign={"start"}
                  textDecoration="underline"
                  sx={{
                    textDecorationColor: "#f0c40d", // Set underline color to yellow
                    textDecorationThickness: "2px", // Set the width of the underline
                  }}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  Data Protection
                </Heading>
                <Text fontSize={{ base: "sm", md: "md" }} textAlign={"justify"}>
                  Data protection involves implementing measures to prevent
                  unauthorized access to computers, websites, and databases. It
                  also safeguards against data loss or corruption, ensuring your
                  information remains secure.
                </Text>
              </Box>

              <Box
                bg="white"
                p={15}
                boxShadow="lg"
                borderRadius="md"
                flex="1"
                m={2} // Add margin for spacing
                position="relative" // Relative positioning
                minW={{ base: "80%", md: "50%" }} // Set min width for responsiveness
                borderColor="#f0c40d" // Set border color to yellow
                borderWidth="1px" // Set the border width
                transition="transform 0.3s ease-in-out" // Smooth transition
                _hover={{
                  transform: "scale(1.05)", // Slightly increase size on hover
                  boxShadow: "xl", // Increase shadow on hover for effect
                }}
                mt={{ base: 5, md: 40 }} // Margin top for large screens
              >
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  mb={{ base: 0, md: 5 }}
                  textAlign={"start"}
                  textDecoration="underline"
                  sx={{
                    textDecorationColor: "#f0c40d", // Set underline color to yellow
                    textDecorationThickness: "2px", // Set the width of the underline
                  }}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  Privacy
                </Heading>
                <Text fontSize={{ base: "sm", md: "md" }} textAlign={"justify"}>
                  Data privacy, or information privacy, refers to an
                  organization’s ability to control what data within its
                  computer systems can be shared with third parties. It ensures
                  that sensitive information is protected and used responsibly.
                </Text>
              </Box>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }} // Column for mobile, row for medium and above
              justifyContent="space-between"
              alignItems="center"
              p={5}
              mt={{ base: 0, md: -150 }}
            >
              <Box
                bg="white"
                p={15}
                boxShadow="lg"
                borderRadius="md"
                flex="1"
                m={2} // Add margin for spacing
                position="relative" // Relative positioning
                minW={{ base: "80%", md: "50%" }} // Set min width for responsiveness
                borderColor="#f0c40d" // Set border color to yellow
                borderWidth="1px" // Set the border width
                transition="transform 0.3s ease-in-out" // Smooth transition
                _hover={{
                  transform: "scale(1.05)", // Slightly increase size on hover
                  boxShadow: "xl", // Increase shadow on hover for effect
                }}
              >
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  mb={{ base: 0, md: 5 }}
                  textAlign={"start"}
                  textDecoration="underline"
                  sx={{
                    textDecorationColor: "#f0c40d", // Set underline color to yellow
                    textDecorationThickness: "2px", // Set the width of the underline
                  }}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  Information Security
                </Heading>
                <Text fontSize={{ base: "sm", md: "md" }} textAlign={"justify"}>
                  Information security is built on three core principles:
                  confidentiality, integrity, and availability. We implement a
                  range of solutions designed to protect vital company
                  information from alteration, disruption, destruction, and
                  unauthorized access.
                </Text>
              </Box>

              <Box
                bg="white"
                p={15}
                boxShadow="lg"
                borderRadius="md"
                flex="1"
                m={2} // Add margin for spacing
                position="relative" // Relative positioning
                minW={{ base: "80%", md: "50%" }} // Set min width for responsiveness
                borderColor="#f0c40d" // Set border color to yellow
                borderWidth="1px" // Set the border width
                transition="transform 0.3s ease-in-out" // Smooth transition
                _hover={{
                  transform: "scale(1.05)", // Slightly increase size on hover
                  boxShadow: "xl", // Increase shadow on hover for effect
                }}
                mt={{ base: 5, md: 40 }} // Margin top for large screens
              >
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  mb={{ base: 0, md: 5 }}
                  textAlign={"start"}
                  textDecoration="underline"
                  sx={{
                    textDecorationColor: "#f0c40d", // Set underline color to yellow
                    textDecorationThickness: "2px", // Set the width of the underline
                  }}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  Transparency
                </Heading>
                <Text fontSize={{ base: "sm", md: "md" }} textAlign={"justify"}>
                  We handle data with integrity and in compliance with the law.
                  We are committed to informing individuals and businesses about
                  the data we collect, who has access to it, how it’s used, and
                  how they can engage with it. Your trust is our priority.
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>

        <Box
          flex="1"
          textAlign={{ base: "center", md: "left" }}
          mt={{ base: 10, md: 0 }}
          ml={{ base: 10, md: 40 }}
          px={{ base: 0, md: 10 }}
          position="relative"
        >
          <Box
            position="absolute"
            left={{ base: "5%", md: "100%" }}
            bottom="10%"
            w={{ base: "120px", md: "100px" }}
            h={{ base: "120px", md: "100px" }}
            //bg="#f0c40d"
            bg="#E9BD36"
            borderRadius="full"
            zIndex={1}
          ></Box>
          

          <Heading
            fontSize={{ base: "2xl", md: "4xl" }}
            mb={4}
            // filter="drop-shadow(1px 1px 8px #f0c40d)"
          >
            We Are GDPR Compliant!
          </Heading>
          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" mb={4}>
            At Explore And Do, we adhere strictly to the General Data Protection
            Regulation (GDPR) guidelines. Our data processing practices for B2B
            communications and marketing programs are designed solely for
            business purposes. We maintain essential business data on EU
            citizens exclusively to support our B2B marketing initiatives. Our
            primary focus is on connecting with businesses, not individuals.
            Your privacy and compliance are our top priorities!
          </Text>
          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" mb={4}>
            At Explore And Do, we prioritize quality assurance in our lead
            generation process to ensure that every lead meets the highest
            standards before delivery. Our rigorous approach involves multiple
            layers of quality checks, including thorough validation of contact
            information, relevance to client criteria, and compliance with
            industry regulations. Each lead undergoes a detailed assessment to
            verify its accuracy and legitimacy, allowing us to provide our
            clients with reliable, high-quality leads. By maintaining strict
            quality control, we help our clients achieve better engagement,
            higher conversion rates, and a strong return on their investment.
            Your success is our priority, and we’re committed to delivering
            leads that truly drive results.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Gdpr;
