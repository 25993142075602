import {
  Box,
  SimpleGrid,
  Icon,
  Heading,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Flex,
  Image,
} from "@chakra-ui/react";
import {
  FaCode,
  FaBullhorn,
  FaLaptop,
  FaChartLine,
  FaLightbulb,
} from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import services_bg from "../../assets/services_bg4.jpg";
import db from "../../assets/big-data.gif";
import lead from "../../assets/employee.gif";
import cs from "../../assets/content-media.gif";
import abm from "../../assets/abm.gif";
import qualification from "../../assets/filter.png";
import email from "../../assets/mail.gif";
const Services = () => {
  const services = [
    {
      icon: FaLaptop,
      image: db,
      title: "Our Database",
      description:
        "Data Solutions offer a comprehensive, organized, and compliant database with standardized prospects and detailed company information, covering North America, LATAM, Europe, ANZ, the Nordics, and the Middle East, Africa, Asia, and APAC regions to meet diverse segmentation needs. We deliver current, authorized B2B leads, solving prospecting issues with our experienced team and reliable information, helping you contact relevant decision-makers at the right time. Our search features allow you to identify and validate contacts based on your target segment's needs, while our B2B data cleansing and audience profiling services provide precise information by merging duplicates and correcting data. This optimized data enhances your understanding of customer purchasing behaviour, leading to higher sales conversion rates.",
    },
    {
      icon: FaBullhorn,
      image: lead,
      title: "Lead Generation",
      description:
        "Our lead generation services provide tailored, high-quality leads to fill your sales funnel. With advanced data, multi-channel capabilities, and lead scoring, your sales teams engage effectively. Through rigorous quality assurance, you can evaluate audience interest and map prospects based on intent. Our marketing and sales-ready leads support relationship-building in prospecting and nurturing. We offer Content Syndication, white paper promotions, MQLs, SQLs, BANT, and ABM strategies to boost ROI.",
    },
    {
      icon: FaCode,
      image: cs,
      title: "Content Syndication",
      description:
        "At Explore And Do, we distribute your content to attract and capture potential leads, benefiting our B2B clients with our excellent demand generation services. Our service targets audience actively seeking information about your products or services, leveraging our comprehensive network and expertise to ensure your content reaches the right prospects. This approach enhances brand visibility, drives engagement, broadens your reach, and increases the quality and quantity of leads generated to support your sales and marketing goals.",
    },
    {
      icon: FaLightbulb,
      image: abm,
      title: "Account Based Marketing",
      description:
        "Our specialty lies in B2B marketing, where our sales and marketing teams collaborate to convert best-fit accounts into clients. Our custom database, based on your ABM list, and marketing team's expertise identifies, engages, and closes deals with key clients, delivering quality leads to enhance marketing ROI, attributed revenue, and conversions. Using the ABM technique, we identify the target audience, craft a custom audience, and deliver qualified leads, aligning sales and marketing efforts.",
    },
    {
      icon: FaChartLine,
      image: qualification,
      title: "Lead Qualification",
      description:
        "All our leads undergo a rigorous quality assurance procedure before entering your channel. Our lead preference function allows you to evaluate audience interest, and prospects are carefully mapped based on their intent. Our marketing and sales-ready leads help maintain relationships established during the prospecting and nurturing phases. Telemarketing leads are reviewed by quality team for accuracy, intent, and compliance with the campaign scope, undergoing two layers of quality audits to ensure they meet client requirements. With these stringent quality measures, we provide high-quality, compliant, and relevant telemarketing leads tailored to your campaign needs.",
    },
    {
      icon: MdMarkEmailRead,
      image: email,
      title: "Email Marketing",
      description:
        "Our email marketing service is customized to drive targeted traffic to optimized landing pages, utilizing compelling content and clear CTAs to encourage effective lead generation. We prioritize double opt-in capabilities for high-quality leads and data protection compliance, complemented by thorough performance tracking and analysis to enhance campaign effectiveness. Our approach includes utilizing targeted email campaigns to direct potential customers to landing pages, crafting engaging content and CTAs, optimizing landing page design, and conducting performance tracking and analysis for continual improvement and refinement of campaign strategies.",
    },
  ];

  return (
    <Box position="relative" pb={20}>
      <Heading
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        textAlign="center"
        mb={6}
        mt={12}
        // filter="drop-shadow(1px 1px 8px #f0c40d)"
      >
        Our Services
      </Heading>

      <Box
        bgImage={`url(${services_bg})`}
        bgSize="cover"
        bgPosition="center"
        height={{ base: "40vh", md: "60vh" }} // Adjust based on screen size
        position="relative"
        zIndex={0}
      />

      <Box
        // bg="#f0c40d"
        bg="gray.50"
        height="auto"
        width="100%"
        py={16} // Padding to create space between background image and content
        position="relative"
        zIndex={1}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }} // Responsive columns
          spacing={8}
          px={{ base: 4, md: 20 }}
        >
          {services.map((service, index) => (
            <Box
              key={index}
              //bg="white"
              //bg="#f0c40d"
              bg="#E9BD36"
              p={6}
              shadow="2xl"
              boxShadow="2xl"
              borderWidth="1px"
              borderRadius="3xl"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              position="relative"
              minH="30vh"
              transition="transform 0.3s ease-in-out"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "2xl",
                shadow: "2xl",
                bg: "white",
              }}
              role="group" // Enable group hover state
            >
              <Flex
                direction="row" // Arrange icon and heading in a row
                alignItems="center"
                mb={4}
                width="100%" // Make sure the flex container spans the width of the card
              >
                <Box
                  bg="white"
                  p={3}
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr={4}
                  flexShrink={0}
                  _groupHover={{ bg: "#f0c40d" }}
                  // role="group" // Enable group hover state
                >
                  <Image
                    src={service.image}
                    w={{ base: 6, md: 8 }}
                    h={{ base: 6, md: 8 }}
                    alt="custom"
                    boxSize="50px"
                    objectFit="cover"
                    mixBlendMode="multiply"
                    transition="transform 0.3s ease-in-out"
                    _groupHover={{ transform: "rotate(360deg)" }} // Rotate image on hover
                  />
                </Box>

                <Heading fontSize={{ base: "lg", md: "xl" }} mb={0} isTruncated>
                  {service.title}
                </Heading>
              </Flex>

              <Text
                mb={4}
                fontSize={{ base: "sm", md: "md" }}
                noOfLines={{ base: 8, md: 10 }} // Limit text lines on smaller screens
                textAlign="justify"
                _hover={{ noOfLines: 14 }}
              >
                {service.description}
              </Text>

              {/* <Popover>
                <PopoverTrigger>
                  <Button
                    bg="#f0c40d"
                    size={{ base: "sm", md: "md" }}
                    mt="auto"
                    ml="auto" 
                  >
                    Read More
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader
                    fontSize={{ base: "lg", md: "xl" }}
                    
                  >
                    {service.title}
                  </PopoverHeader>
                  <PopoverBody
                 
                  >
                    {service.description}
                  </PopoverBody>
                </PopoverContent>
              </Popover> */}
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Services;
