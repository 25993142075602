import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Textarea,
  useColorModeValue,
  VStack,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import React from "react";

const ContactUs = () => {
  const bgColor = useColorModeValue("white", "gray.50");
  const formBgColor = useColorModeValue("gray.50", "gray.100");

  return (
    <Box position="relative" bg={bgColor} py={{ base: 10, md: 20 }} px={5}>
      
      {/* Responsive SVG Background */}
      <Box position="absolute" top={0} left={0} width="100%" height="100%">
        <svg
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <path
            //fill="#f0c40d"
            fill="#E9BD36"
            d="M0,288L30,266.7C60,245,120,203,180,186.7C240,171,300,181,360,197.3C420,213,480,235,540,218.7C600,203,660,149,720,117.3C780,85,840,75,900,96C960,117,1020,171,1080,186.7C1140,203,1200,181,1260,160C1320,139,1380,117,1410,106.7L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320H0Z"
          />
        </svg>
        
      </Box>

      {/* Triangle on the Left Side */}
      <Box
        position="absolute"
        top="20%"
        left={0}
        transform="translateY(-50%)"
        width={0}
        height={0}
        borderLeft="100px solid transparent"
        borderBottom="100px solid #E9BD36" // Triangle color
      />

      <Flex
        direction={{ base: "column", md: "row" }}
        justify="center"
        align="center"
        width={{ base: "100%", md: "90%" }}
        mx="auto"
        zIndex={1} // Ensure content is above the SVG
        px={{ base: 4, md: 0 }} // Add padding for mobile
      >
        {/* Left Section: Get in Touch Text */}
        <Box
          flex="1"
          textAlign={{ base: "center", md: "left" }}
          pr={{ md: 10 }} // Add padding to right in larger screens
          mb={{ base: 10, md: 0 }} // Add margin bottom for mobile view
        >
          <VStack spacing={5} align={{ base: "center", md: "start" }} position="relative">
            <Heading fontSize={{ base: "2xl", md: "3xl" }} color="black"
            // filter="drop-shadow(1px 1px 8px #f0c40d)"
            >
              Get in Touch for High-Quality Leads
            </Heading>
            <Text fontSize={{ base: "sm", md: "md" }} color="gray.600" maxW="md">
              Ready to elevate your business with high-quality leads? Contact us
              today to learn how Explore And Do Technologies can help you
              achieve your demand generation goals. Let’s discuss your needs and
              explore the possibilities together! We look forward to partnering
              with you!
            </Text>
          </VStack>
        </Box>

        {/* Right Section: Contact Form */}
        <Box
          flex="1"
          bg={formBgColor}
          p={8}
          borderRadius="lg"
          boxShadow="xl"
          width={{ base: "100%", md: "60%" }}
        >
          <VStack spacing={5}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Your Name"
                bg="white"
                _placeholder={{ color: "gray.500" }}
                borderRadius="md"
                size="lg"
                focusBorderColor="yellow.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="you@example.com"
                bg="white"
                _placeholder={{ color: "gray.500" }}
                borderRadius="md"
                size="lg"
                focusBorderColor="yellow.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Phone</FormLabel>
              <Input
                type="tel"
                placeholder="+1 (123) 456-7890"
                bg="white"
                _placeholder={{ color: "gray.500" }}
                borderRadius="md"
                size="lg"
                focusBorderColor="yellow.400"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Website</FormLabel>
              <Input
                placeholder="www.yourwebsite.com"
                bg="white"
                _placeholder={{ color: "gray.500" }}
                borderRadius="md"
                size="lg"
                focusBorderColor="yellow.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Message</FormLabel>
              <Textarea
                placeholder="Your message..."
                bg="white"
                _placeholder={{ color: "gray.500" }}
                borderRadius="md"
                size="lg"
                rows={4}
                focusBorderColor="yellow.400"
              />
            </FormControl>
          </VStack>

          <Button
            mt={8}
            width="100%"
            bg="black"
            color="#f0c40d" // Text color as per your requirement
            size="lg"
            _hover={{ color: "#f0c40d", bg: "black" }} // Reverse on hover
          >
            Submit
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactUs;
