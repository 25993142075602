import {
  Box,
  Heading,
  Image,
  SimpleGrid,
  Text,
  Icon,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaLaptop, FaRegSmileBeam, FaTeamspeak } from "react-icons/fa";
import { MdOutlineLeaderboard } from "react-icons/md";
import { PiMicrosoftTeamsLogoLight } from "react-icons/pi";
import alteryx from "../../assets/alteryx.jpg";
import databricks from "../../assets/databricks.jpg";
import lenovo from "../../assets/lenovo.jpg";
import dell from "../../assets/dell.jpg";
import tmobile from "../../assets/tmobile.jpg";
import aveva from "../../assets/aveva.jpg";
import microsoft from "../../assets/microsoft.jpg";
import aws from "../../assets/aws.jpg";
import hsbc from "../../assets/hsbc.jpg";
import siemens from "../../assets/siemens3.png";
import hcl from "../../assets/hcl2.png";
import paloalto from "../../assets/paloalto.jpg";
import unisys from "../../assets/unisys.jpg";
import equinix from "../../assets/equinix.jpg";
import veeam from "../../assets/veeam.jpg";
import messagebird from "../../assets/messagebird.jpg";
import omada from "../../assets/omada.jpg";
import safesoftware from "../../assets/safesoftware.jpg";
import blackline from "../../assets/blackline.jpg";
import cloudflare from "../../assets/cloudflare.jpg";
import radware from "../../assets/radware.jpg";
import dremio from "../../assets/dremio.jpg";
import about1 from "../../assets/about1.jpeg";
import about2 from "../../assets/about2.jpg";
import a1 from "../../assets/abstract-shape.gif";
import a2 from "../../assets/abstract-shape1.gif";
import g1 from "../../assets/grad1.gif";
import g2 from "../../assets/g2.gif";
import g3 from "../../assets/g3.gif";
import g4 from "../../assets/g4.gif";
import g5 from "../../assets/g5.gif";
import g6 from "../../assets/g6.gif";
const AboutUs = () => {
  const icons = [
    { icon: FaLaptop, text: "Year's Of Experience", number: 4 },
    { icon: FaRegSmileBeam, text: "Happy Clients", number: 8 },
    { icon: MdOutlineLeaderboard, text: "Lead's in a month", number: 1200 },
    { icon: PiMicrosoftTeamsLogoLight, text: "Dedicated Team", number: 30 },
  ];

  const icons2 = [
    { image: databricks, text: "Data Bricks" },
    { image: lenovo, text: "Lenovo" },
    { image: aveva, text: "Aveva" },
    { image: hsbc, text: "HSBC" },
    { image: dell, text: "Dell" },
    { image: microsoft, text: "Microsoft" },
    { image: aws, text: "AWS" },
    { image: equinix, text: "Equinix" },
    { image: hcl, text: "HCL" },
    { image: unisys, text: "Unisys" },

    { image: siemens, text: "Siemens" },
    { image: alteryx, text: "Alteryx" },
    // {image:paloalto, text: "Paloalto"},
    // {image:unisys, text: "Unisys"},
    // {image:veeam, text: "Veeam"},
    //{image:tmobile, text: "T-Mobile"},
    // {image:messagebird, text: "MessageBird"},
    // {image:alteryx, text: "Alteryx"},
    // {image:omada, text: "Omada"},
    // {image:safesoftware, text: "Safe Software"},
    // {image:alteryx, text: "Ping Identity"},
    // {image:blackline, text: "BlackLine"},
    // {image:cloudflare, text: "Cloudflare"},
    // {image:radware, text: "Radware"},
    // {image:dremio, text: "Dremio"},
  ];

  return (
    <Box
      bgColor="white"
      bgPosition="center"
      mt={{ base: "200px", md: "120px" }}
      py={20}
      px={{ base: 4, md: 8, lg: 16 }} // Padding for different screen sizes
      textAlign="center" // Center-align the text
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
        gap={8}
        width={{ base: "90%", md: "80%", lg: "70%" }}
        mx="auto" // Center the entire Flex container
      >
        {/* Text Section */}
        <Box flex="1" maxW={{ base: "100%", md: "50%" }}>
          <Heading
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }} // Responsive font size
            mb={6}
          >
            About Us
          </Heading>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            lineHeight={{ base: "tall", md: "taller" }}
            textAlign="justify"
            mb={4}
          >
            At Explore And Do Technologies, we specialize in transforming your
            business through targeted B2B demand and lead generation services.
            Our mission is to empower organizations with expertly crafted
            solutions that drive real, measurable results.
          </Text>

          <Text
            fontSize={{ base: "md", md: "lg" }}
            lineHeight={{ base: "tall", md: "taller" }}
            textAlign="justify"
            mb={4}
          >
            We believe in the power of quality over quantity. Our meticulously
            designed lead generation strategies focus on delivering high-value
            leads that align perfectly with your business goals. By precisely
            targeting the right audience, we ensure that every lead we generate
            is a valuable asset to your sales team.
          </Text>

          <Text
            fontSize={{ base: "md", md: "lg" }}
            lineHeight={{ base: "tall", md: "taller" }}
            textAlign="justify"
          >
            Our approach seamlessly transitions potential leads into your sales
            pipeline, maximizing your return on investment (ROI). With a
            commitment to excellence, we provide comprehensive lead generation
            services that guarantee exceptional sales leads and outstanding
            email deliverability. This not only enhances the effectiveness of
            your outbound marketing campaigns but also propels your business to
            new heights.
          </Text>
        </Box>

        {/* Image Section */}
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
        >
          <Image
            src={about1}
            width={{ base: "200px", md: "250px", lg: "500px" }}
            height={{ base: "200px", md: "250px", lg: "280px" }}
            borderRadius="20px"
            objectFit="cover"
            mb={4}
          />
           <Box
            position="absolute"
            left={{ base: "5%", md: "30%" }}
            bottom="30%"
            w={{ base: "70px", md: "100px" }}
            h={{ base: "70px", md: "100px" }}
            //bg="#f0c40d"
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g6}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
              // width={{ base: "20px", md: "20px", lg: "50px" }}
              // height={{ base: "20px", md: "20px", lg: "50px" }}
            />
           
          </Box>
          <Box
            position="absolute"
            left={{ base: "5%", md: "5%" }}
            bottom="10%"
            w={{ base: "70px", md: "100px" }}
            h={{ base: "70px", md: "100px" }}
            //bg="#f0c40d"
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g2}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
              // width={{ base: "20px", md: "20px", lg: "50px" }}
              // height={{ base: "20px", md: "20px", lg: "50px" }}
            />
            <Image
              src={g5}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
              // width={{ base: "20px", md: "20px", lg: "50px" }}
              // height={{ base: "20px", md: "20px", lg: "50px" }}
            />
          </Box>

          <Image
            src={about2}
            width={{ base: "200px", md: "250px", lg: "500px" }}
            height={{ base: "200px", md: "250px", lg: "280px" }}
            ml={{ base: 0, md: "250px", lg: "500px" }}
            borderRadius="20px"
            objectFit="cover"
          />
          <Box
            position="absolute"
            left={{ base: "5%", md: "100%" }}
            bottom="100%"
            w={{ base: "70px", md: "80px" }}
            h={{ base: "70px", md: "80px" }}
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g1}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
            />
          </Box>
          <Box
            position="absolute"
            left={{ base: "5%", md: "98%" }}
            bottom="98%"
            w={{ base: "70px", md: "80px" }}
            h={{ base: "70px", md: "80px" }}
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g5}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
            />
          </Box>
          <Box
            position="absolute"
            left={{ base: "5%", md: "90%" }}
            bottom="50%"
            w={{ base: "70px", md: "80px" }}
            h={{ base: "70px", md: "80px" }}
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g3}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
            />
          </Box>
        </Box>
      </Flex>

      {/* Icons Section */}
      <Box display="flex" justifyContent="center" mb={12}>
        <Box
          width={{ base: "90%", md: "80%", lg: "70%" }}
          py={8}
          borderRadius="md"
        >
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 4 }}
            spacing={8}
            alignItems="center"
            justifyItems="center"
          >
            {icons.map((item, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {/* Icon inside rounded box */}
                <Box
                  p={5}
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={{ base: "60px", md: "80px" }}
                  height={{ base: "60px", md: "80px" }}
                >
                  {item.icon ? (
                    <Icon
                      as={item.icon}
                      w={{ base: 10, md: 24 }}
                      h={{ base: 10, md: 24 }}
                      //color="#f0c40d"
                      color="#E9BD36"
                      //transition="transform 0.3s ease-in-out"
                      _hover={{ transform: "scale(1.05)" }}
                    />
                  ) : (
                    <Image
                      src={item.image}
                      alt="custom"
                      boxSize="40px"
                      objectFit="cover"
                      borderRadius="full"
                    />
                  )}
                </Box>
                <AnimatedCounter endNumber={item.number} />
                <Text
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="bold"
                  mt={2}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  {item.text}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignContent={"center"}
        //width={{ base: "90%", md: "80%", lg: "70%" }}
        bg={"whiteAlpha.500"}
        boxShadow={"2xl"}
        borderRadius="3xl"
      >
        <Heading
          justifyContent="center"
          alignContent={"center"}
          textAlign={"start"}
          marginLeft={12}
          fontSize={{ base: "md", md: "xl", lg: "4xl" }}
        >
          Impactful Campaigns Delivered For Industry Leaders
        </Heading>
        <Box
          width={{ base: "90%", md: "80%", lg: "70%" }}
          py={4}
          borderRadius="md"
          //border={"1px solid black"}
        >
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 4 }}
            spacing={2}
            alignItems="center"
            justifyItems="center"
          >
            {icons2.map((item, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {/* Icon inside rounded box */}
                <Box
                  p={4}
                  //borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={{ base: "80px", md: "100px" }}
                  height={{ base: "80px", md: "100px" }}
                >
                  <Image
                    src={item.image}
                    alt="custom"
                    //boxSize="100px"
                    objectFit="cover"
                    // mixBlendMode={"darken"}
                    transition="transform 0.3s ease-in-out"
                    //_hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
                  />
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};

const AnimatedCounter = ({ endNumber }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const duration = 2000; // Duration of the counter animation (in milliseconds)
    const stepTime = Math.abs(Math.floor(duration / endNumber)); // Calculate time per step

    const timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === endNumber) {
        clearInterval(timer);
      }
    }, stepTime);

    return () => {
      clearInterval(timer);
    };
  }, [endNumber]);

  return (
    <Text
      mt={4}
      fontSize={{ base: "lg", md: "3xl" }}
      fontWeight="bold"
      color="#f0c40d"
    >
      {count}
    </Text>
  );
};

export default AboutUs;
