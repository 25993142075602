import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Heading,
  Flex,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  Image,
  InputRightAddon,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { FaLaptop, FaRegSmileBeam, FaTeamspeak } from "react-icons/fa";
import { MdOutlineLeaderboard } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { PiMicrosoftTeamsLogoLight } from "react-icons/pi";

const OurProcess = () => {
  const icons = [
    { icon: FaLaptop, text: "Year's Of Experience", number: 4 },
    { icon: FaRegSmileBeam, text: "Happy Clients", number: 8 },
    { icon: MdOutlineLeaderboard, text: "Lead's in a month", number: 1200 },
    { icon: PiMicrosoftTeamsLogoLight, text: "Dedicated Team", number: 30 },
  ];

  const services = [
    {
      title: "Data Research & Contact Discovery",
      description:
        "We start by conducting thorough data research to identify potential leads. Using advanced tools and methodologies, we discover relevant contacts that fit your target market, ensuring a solid foundation for your outreach efforts.",
    },
    {
      title: "Lead Scoring & Targeting the Right Audience",
      description:
        "Our team employs lead scoring techniques to prioritize leads based on their engagement and fit. By focusing on the right audience, we enhance the quality of leads, ensuring they align with your specific business objectives.",
    },
    {
      title: "Lead Qualification",
      description:
        "Our leads undergo rigorous quality checks to ensure accuracy. We carefully qualify each lead, evaluating their interest, budget and authority, ensuring that only the most promising leads are passed along for conversion.",
    },
    {
      title: "Feedback & Improvement",
      description:
        "After the lead qualification stage, we gather feedback from your sales team to evaluate lead performance. This continuous loop of feedback allows us to refine our strategies and improve our processes, ensuring better results with each campaign.",
    },
  ];

  return (
    <Box py={20}>
      <Heading
        textAlign="center"
        mb={8}
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        // filter="drop-shadow(1px 1px 8px #f0c40d)"
      >
        Our Process
      </Heading>
      <Text
        textAlign="center"
        mb={12}
        fontSize={{ base: "sm", md: "md", lg: "lg" }}
      >
        At Explore And Do Technologies, we employ a structured four-step process
        to ensure effective lead generation:
      </Text>

      {/* Services Section */}
      {/* <Box display="flex" justifyContent="center" mb={12}>
        <Box
          width={{ base: "90%", md: "80%", lg: "70%" }}
          py={8}
          borderRadius="md"
        >
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 4 }}
            spacing={8}
            alignItems="center"
            justifyItems="center"
          >
            {services.map((service, index) => (
              <Box
                key={index}
                bg="white"
                p={6}
                shadow="md"
                borderWidth="1px"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={6}
                borderRadius={10}
                minH={{ base: "auto", md: "35vh" }}
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
              >
                <Heading fontSize={{ base: "lg", md: "xl" }} textAlign="left" filter="drop-shadow(1px 1px 8px #f0c40d)">
                  {service.title}
                </Heading>
               
                <Text
                  fontSize={{ base: "sm", md: "md" }}
                  textAlign="left"
                  overflow="hidden" // Ensure overflow text is hidden
                  textOverflow="ellipsis" // Show ellipsis if text overflows
                >
                  {service.description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box> */}

      <Box display="flex" justifyContent="center" mb={12}>
        <Box
          width={{ base: "95%", md: "85%", lg: "75%", xl: "70%" }}
          py={8}
          borderRadius="md"
        >
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3, lg: 4 }} // Responsive columns for balanced layout
            spacing={8}
            alignItems="stretch" // Ensure equal height for all boxes
            justifyItems="center"
          >
            {services.map((service, index) => (
              <Box
                key={index}
                bg="white"
                p={6}
                shadow="md"
                borderWidth="1px"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={4}
                borderRadius={10}
                minH={{ base: "auto", sm: "30vh", md: "35vh", lg: "40vh" }} // Set responsive min height
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
              >
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "xl" }}
                  textAlign="left"
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                  noOfLines={3} // Limit title to 2 lines
                >
                  {service.title}
                </Heading>

                <Text
                  fontSize={{ base: "sm", md: "md" }}
                  textAlign="justify"
                  noOfLines={10} // Limit description to 4 lines for uniformity
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {service.description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box>

      {/* Icons Section */}
      {/* <Box display="flex" justifyContent="center" mb={12}>
        <Box
          width={{ base: "90%", md: "80%", lg: "70%" }}
          py={8}
          borderRadius="md"
        >
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 4 }}
            spacing={8}
            alignItems="center"
            justifyItems="center"
          >
            {icons.map((item, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
               
                <Box
                  p={5}
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={{ base: "60px", md: "80px" }}
                  height={{ base: "60px", md: "80px" }}
                >
                  {item.icon ? (
                    <Icon
                      as={item.icon}
                      w={{ base: 10, md: 24 }}
                      h={{ base: 10, md: 24 }}
                      color="#f0c40d"
                      transition="transform 0.3s ease-in-out"
                      _hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
                    />
                  ) : (
                    <Image
                      src={item.image}
                      alt="custom"
                      boxSize="40px"
                      objectFit="cover"
                      borderRadius="full"
                    />
                  )}
                </Box>
                <AnimatedCounter endNumber={item.number} />
                <Text
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="bold"
                  mt={2}
                  
                >
                  {item.text}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box> */}

      {/* Get in Touch Section */}
      <Box display="flex" justifyContent="center" py={{ base: 10, md: 10 }}>
        <Box
          //bg="#f0c40d"
          bg="#E9BD36"
          width={{ base: "80%", md: "70%", lg: "60%" }}
          p={10}
          borderTopLeftRadius={10}
          borderBottomLeftRadius={120}
          borderTopRightRadius={120}
          borderBottomRightRadius={10}
          boxShadow="md"
          // transition="transform 0.3s ease-in-out"
          // _hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="space-around"
          >
            <Box textAlign={{ base: "center", md: "center" }} py={2}>
              <Heading fontSize={{ base: "xl", md: "3xl" }}>
                Get in Touch!
              </Heading>
            </Box>
            <Box py={2} w={{ base: "100%", md: "auto" }}>
              <Stack spacing={4}>
                <InputGroup>
                  <InputLeftAddon>Email</InputLeftAddon>
                  <Input
                    placeholder=""
                    bgColor="white"
                    w={{ base: "full", md: "md" }}
                    focusBorderColor="black"
                  />
                  <InputRightElement width="4.5rem" mr={2}>
                    <Button
                      h="1.75rem"
                      size="md"
                      bg="#f0c40d"
                      color="black"
                      _hover={{
                        bg: "black", // Change background to yellow on hover
                        color: "#f0c40d", // Change text color to black on hover
                      }}
                    >
                      Submit
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

const AnimatedCounter = ({ endNumber }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const duration = 2000; // Duration of the counter animation (in milliseconds)
    const stepTime = Math.abs(Math.floor(duration / endNumber)); // Calculate time per step

    const timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === endNumber) {
        clearInterval(timer);
      }
    }, stepTime);

    return () => {
      clearInterval(timer);
    };
  }, [endNumber]);

  return (
    <Text
      mt={4}
      fontSize={{ base: "lg", md: "3xl" }}
      fontWeight="bold"
      color="#f0c40d"
    >
      {count}
    </Text>
  );
};

export default OurProcess;
